import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../../translations/messages.en.json'
import enMt from '../../translations/mt/messages.en.json'
import ru from '../../translations/messages.ru.json'

import $ from 'jquery';
import 'jquery.cookie';

// import * as Sentry from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';

// Sentry.init({
//     dsn: process.env.SENTRY_TEAM_JS_DSN,
//     integrations: [new Integrations.BrowserTracing()],
//     environment: process.env.APP_ENV,
//     tracesSampleRate: 0.1,
// });

i18next
    .use(LanguageDetector)
    .init({
        debug: process.env.APP_ENV === 'dev',
        detection: {
            lookupQuerystring: 'lang',
            lookupCookie: 'lang',
            lookupLocalStorage: 'lang',
            lookupSessionStorage: 'lang',
        },
        // lng: process.env.LANG,
        fallbackLng: process.env.APP_LOCALE,
        supportedLngs: process.env.APP_LOCALES.split('|'),
        resources: {
            ru: {
                translation: ru
            },
            en: {
                translation: Object.assign(en, enMt)
            }
        }
    });

window.localeFullname = {
    ru: "ru-RU",
    en: "en-US"
}
window.localeFullname2 = {
    ru: "ru_RU",
    en: "en_US"
}
window.localeFullname3 = {
    ru: "ru",
    en: "en-GB"
}
window.localeDomainParts = {
    ru: "autotrade.su",
    en: "glass.pavelauto.parts"
}
window.i18next = i18next;

import '../../design/css/main.css';
if (i18next.language === 'en') {
    import('../scss/new/app.scss');
}
import '../scss/app.scss';

import '../../design/js/global';
import Global from './pages/global';

const global = new Global();
global.initEnv();
global.initGlobalFunction();
global.initAxios();