import axios from "axios";
import $ from "jquery";

export default class Global {
    initGlobalFunction() {
        window.markupSwitchShowHidePrice = this.markupSwitchShowHidePrice;
    }

    initEnv() {
        window.env = process.env
        window.env.is_markup_price = $('#is_markup_price').length ? parseInt($('#is_markup_price').val()) : 0;
        window.env.checked_hide_price = $('#flagHidePrice').length ? parseInt($('#flagHidePrice').val()) : 0;
    }

    initAxios() {
        axios.defaults.timeout = 10000000;

        let currentRequests = {};
        // Добавляем перехват запросов
        axios.interceptors.request.use(function (config) {
            currentRequests[config.url] = config;

            // Здесь можете сделать что-нибудь с перед отправкой запроса
            return config;
        }, function (error) {
            // Сделайте что-нибудь с ошибкой запроса
            return Promise.reject(error);
        });

        // Добавляем перехват ответов
        axios.interceptors.response.use(function (response) {
            // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
            // Здесь можете сделать что-нибудь с ответом
            return response;
        }, function (error) {
            // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
            // Здесь можете сделать что-то с ошибкой ответа
            return Promise.reject(error);
        });
    }

    markupSwitchShowHidePrice() {
        let $numberOfItemsInCart = $('#number-of-items-in-cart');
        let $priceOfItemsInCart = $('#price-of-items-in-cart');
        if (window.env.is_markup_price) {
            $priceOfItemsInCart.css({'color': 'transparent', 'width': $numberOfItemsInCart.width()})
        } else {
            $priceOfItemsInCart.css({'color': '', 'width': ''})
        }
    }
}
