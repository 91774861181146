$('body')
    .on('change.switch-locale', '.switch-locale', (e) => {
        let lang = $(e.currentTarget).val();
        if (lang) {
            $.cookie('lang', lang, {expires: 365, path: '/'})
            location.reload();
        }
    })
    .delegate('#confirm-cookie', 'click', function (e) { /*206420 и.12356*/
        e.preventDefault();
        $.cookie('confirm-cookie', 1, {expires: 365, path: '/'});
        $('.cookie').remove();
        $('#footer').removeAttr('style');
        $('body').css('padding-bottom', '');
        $('#back-top').css('margin-bottom', '');
        $('.b24-widget-button-visible').css('margin-bottom', '');
        if ($('.div-save-profile:visible').length !== 0) {
            $('.div-save-profile').css('bottom', '');
        }
        if ($('.div-additional-group-buttons:visible').length !== 0) {
            $('.div-additional-group-buttons').css('bottom', '');
        }
    });

$(document).ready(function () { /*206366 и.12183*/
    if (window.i18next.language === 'en') {
        // TODO было 92, поменял на 80, чтобы высота не превышала 132px.
        //  Иначе для плашки в шапке таблице поиска, при скроле вниз, устанавливается не верное значение атрибута top
        //  который высчитывается из высоты .topMenu0
        let minHeight = $('.tmLeft').outerWidth(true) + $('.tmRight').outerWidth(true) + 20 > $('.topMenu0').width()
            ? 80 : 52;
        if ($('#KOD_USER').is(':visible')) {
            minHeight += 52;
        }
        $('.topMenu0').css('min-height', minHeight);
    }
    $('body').css('padding-top', $('.topMenu0').outerHeight());

    if ($('.cookie:visible').length !== 0) { /*206420 и.12356*/
        $('body').css('padding-bottom', $('.cookie').outerHeight());

        let bottom = 44;
        $('#back-top').css('margin-bottom', bottom);
        // Виджет B24 появляется с задержкой
        for (let i = 0; i < 15000; i += 1000) {
            setTimeout(function () {
                if ($('.b24-widget-button-visible').length !== 0) {
                    let marginBottom = parseInt($('.b24-widget-button-visible').css('margin-bottom'));
                    if (isNaN(marginBottom) || marginBottom == 0) {
                        $('.b24-widget-button-visible').css('margin-bottom', bottom);
                        return false;
                    }
                }
            }, i);
        }
    }

    $(window).trigger('resize');
});
